import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ops-center-v2"
    }}>{`Ops Center v2`}</h1>
    <p><a parentName="p" {...{
        "href": "https://ops-center.entur.io/"
      }}>{`Ops Center`}</a>{` is mainly used by Team Sales to check, troubleshoot and handle data related to the sales section of Entur.
This includes data on orders, payments, credits, tickets, and more. It is also used by Support to resend tickets and get reports of reservations.`}</p>
    <p>{`Most pages include a search field. Entering a search parameter brings up suggestions for items that can be searched for.
Select the item you want to search for and enter the name of the item. It is also possible to choose number of lines visible by typing "per page" and set a number.`}</p>
    <h2 {...{
      "id": "menu-items"
    }}>{`Menu items`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Item`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Access`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><Link href="###Orders" mdxType="Link">{`Orders`}</Link>{`, `}<Link href="###Payments" mdxType="Link">{`Payments`}</Link>{`, `}<Link href="###Credits" mdxType="Link">{`Credits`}</Link>{`, `}<Link href="###Ticket Distribution Groups" mdxType="Link">{`Ticket Distribution Groups`}</Link>{`, `}<Link href="###Sales Processes" mdxType="Link">{`Sales Processes`}</Link>{`, `}<Link href="###Tariff Codes" mdxType="Link">{`Tariff Codes`}</Link></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Entur email`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`List of all orders, payments, credits, ticket distribution groups, sales processes, and tariff codes with detailed information.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><Link href="###Reports: Reservations" mdxType="Link">{`Reports: Reservations`}</Link></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Team Sales, Support`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Search and download reports of trains with reservations.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><Link href="###Payment Configurations and Payment Methods" mdxType="Link">{`Payment Configurations and Payment Methods`}</Link></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Team Sales`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`List of all payment configurations and payment methods. Search, add and delete.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><Link href="###Transmission Errors" mdxType="Link">{`Transmission Errors`}</Link>{`T`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Team Sales`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`List of all Transmission Errors. Search by ID, Type, Order ID and more.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "orders"
    }}>{`Orders`}</h3>
    <p>{`List of all orders with the most relevant data (fetched with GET /v1/orders). Click on an Order ID to see detailed information about the order.
This includes information about all order lines, order history, credits, reservations (seats),
ticket distribution groups and sales processes linked to this order. Here you also have the option to re-send tickets to customers.
Se the full JSON-structure by clicking on "View full order" under the Order History tab.
Search by Order ID, organization, status, version, total amount, and more.`}</p>
    <h3 {...{
      "id": "payments"
    }}>{`Payments`}</h3>
    <p>{`List of all payments with the most relevant data (fetched with GET /v1/payments). Click on an Payment ID to see detailed information about transactions linked to the payment.
It is also possible to get the JSON-structure by clicking on the transaction you want to see. Search by Payment ID, Organisation, Status, Payment Amount, and more.`}</p>
    <h3 {...{
      "id": "credits"
    }}>{`Credits`}</h3>
    <p>{`List of all credits with the most relevant data (fetched with GET /v1/credits). Click on an Credit ID to see detailed information about transactions linked to the credit.
It is also possible to get the JSON-structure by clicking on the transaction you want to see. Search by Order ID, Payment ID, Organisation, Credit Amount, and more.`}</p>
    <h3 {...{
      "id": "ticket-distribution-groups"
    }}>{`Ticket Distribution Groups`}</h3>
    <p>{`List of all Ticket Distribution Groups (fetched with GET /v1/ticket-distribution-groups). Click on an ID to see detailed information about all distributions.
It is also possible to get the JSON-structure by clicking on the distribution you want to see. This is the data used to create a ticket. Search by ID, Order ID, and more.`}</p>
    <h3 {...{
      "id": "sales-processes"
    }}>{`Sales Processes`}</h3>
    <p>{`List of all Sales Processes (fetched with GET /v1/sales-processes). By clicking on an ID you will get to a new page with more detailed information about all the tasks and reservations linked to the ID.
Search by Sales Process ID, Order ID, Organisation, Status and, more.`}</p>
    <h3 {...{
      "id": "tariff-codes"
    }}>{`Tariff Codes`}</h3>
    <p>{`List of all Tariff Codes (fetched with GET /v1/tariff-codes). Search by Sales Process ID, Order ID, Organisation, Status, and more.`}</p>
    <h3 {...{
      "id": "reports-reservations"
    }}>{`Reports: Reservations`}</h3>
    <p>{`Search and download reports of trains with reservations. There are two tabs on this page, "Search" and "Manual". Use "Manual" to download reports directly by knowing the datedServicejourneyID.
If you do not know the datedServiceJourneyId, you can use "Search" to search by Date, Authority (organisation), Line and Departure. The reports include information about the order and traveller.`}</p>
    <h3 {...{
      "id": "payment-configurations-and-payment-methods"
    }}>{`Payment Configurations and Payment Methods`}</h3>
    <p>{`List of all Payment Configurations and Payment Methods. Search, add and delete. Contact Team Sales if you need to create, update or delete one of these. These pages have limited access control due to sensitive payment information.`}</p>
    <h3 {...{
      "id": "transmission-errors"
    }}>{`Transmission Errors`}</h3>
    <p>{`List of all Transmission Errors. Click on the magnifying glass (icon on the right side), to get to a new page with more information, JSON-structure and the opportunity to resend and delete the error.
Search by ID, Type, Order ID and more.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      